.hero-container {
    background: #0e0e0e;
    color: #f5f5f5;
    padding: 4rem 2rem;
    text-align: center;
    font-family: 'Roboto Mono', monospace;
  }
  
  .hero-container h1 {
    font-size: 3rem;
    color: #00ff99;
  }
  
  .hero-container h2 {
    font-size: 2rem;
    color: #00d1ff;
  }
  
  .hero-container p {
    font-size: 1.1rem;
    max-width: 600px;
    margin: 1rem auto 2rem auto;
    line-height: 1.6;
  }
  
  .hero-metrics {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  
  .metric-box {
    background: #1f1f1f;
    padding: 1rem 2rem;
    border-radius: 10px;
    border: 1px solid #333;
    min-width: 160px;
  }
  
  .metric-label {
    font-size: 0.85rem;
    color: #aaa;
  }
  
  .metric-value {
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
  }
  