.footerbar {
    margin-top: 50px;
    position: relative;
    top: 0;
    left: 0; 
    width: 100%;
    background-color: #081623;
    height: 250px;
  }
  
  .footerbar__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .footerbar__logo__image {
    width: 40px;
    margin-right: 10px;
  }
  
  .footer {
    padding: 2rem 1rem;
    margin-top: 4rem;
    border-top: 1px solid #292c33;
    background-color: #0f1115;
    color: #888;
    text-align: center;
  }
  
  
  .footer_links {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  footer li {
    padding: 0.5rem 0;
    text-align: center;
  }
  
  footer a {
    font-size: 24px;
    color: #fff;
    text-decoration: none;
  }
  
  footer p {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
  }
  
  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 20px;
  }
  
  .footer_columns {
    display: flex;
    justify-content: center;
  }
  
  @media (max-width: 500px) {
    .footerbar {
      height: 300px;
    }
  
    .footer_columns {
      flex-direction: column;
      align-items: center;
    }
  }
  