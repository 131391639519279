.navbar {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    background-color: #0f1115;
    box-shadow: 0 0 10px rgba(0, 255, 160, 0.1);
    border-bottom: 1px solid #292c33;
    padding: 0.5rem 1rem;
  }
  
  .navbar-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    height: 60px;
  }
  
  .navbar__logo {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .navbar__logo__image {
    width: 36px;
    height: auto;
  }
  
  .navbar__logo p {
    font-size: 1.5rem;
    color: #00f5a0;
    font-weight: bold;
    margin: 0;
  }
  
  .navbar__items ul {
    display: flex;
    list-style: none;
    gap: 1.25rem;
  }
  
  .navbar__items li a {
    text-decoration: none;
    color: #eaeaea;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  .navbar__items li a:hover {
    color: #00c9ff;
  }
  
  .navbar__toggle_button {
    display: none;
  }
  
  @media (max-width: 940px) {
    .navbar__items {
      display: none;
    }
  
    .navbar__toggle_button {
      display: block;
    }
  }
  