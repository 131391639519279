.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  padding: 3rem 1rem;
  background-color: #1a1d23;
  color: #eaeaea;
  border-radius: 12px;
  box-shadow: 0 0 30px rgba(0, 255, 160, 0.05);
  border: 1px solid #292c33;
  transition: transform 0.3s ease;
  font-family: 'IBM Plex Mono', monospace;

}

.card:hover {
  transform: scale(1.01);
  box-shadow: 0 0 40px rgba(0, 245, 160, 0.15);
}

.section-header h2 {
  color: #00f5a0;
  font-size: 2rem;
  margin-bottom: 10px;
}

.section-header h2 + h2 {
  font-size: 1.25rem;
  font-weight: normal;
  color: #00c9ff;
}

.card-container ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.card-container li {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 8px;
}

.address-container h4 {
  font-weight: 400;
  color: #aaa;
}

@media (max-width: 480px) {
  .card {
    padding: 25px 20px;
  }

  .section-header h2 {
    font-size: 1.5rem;
  }

  .card-container li {
    font-size: 1rem;
  }
}
